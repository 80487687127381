import 'react-app-polyfill/ie9'; // For IE 9-11 support

// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import axios from 'axios'
import App from './App';
import * as serviceWorker from './serviceWorker';   

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = JSON.parse(localStorage.getItem('token'))
    if (token)
    config.headers.Authorization =  "Bearer "+token.token;
    config.headers.Accept="application/json"
    return config;
});
axios.interceptors.response.use(
    function(response) { return response;}, 
    function(error) {
        // handle error
        if (error.response) {
        if(error.response.status==401)
           window.location.href="/login";
        }
        return Promise.reject(error.response);
    });
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
