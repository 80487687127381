import React from 'react'
import { isArray } from 'util';
export default function Auth (props)
{
let state={}
        let userPermission=JSON.parse(localStorage.getItem("permission"));
if(userPermission && isArray(userPermission))
{
    state={

        userPermission:userPermission,
        allowdPermission:props.permission,
        permission:true
    }

    let permission=state.allowdPermission.filter(allow=>{
        let allowed=state.userPermission.find(user_per=>user_per.key===allow)
        return allowed?true:false;
    })

    if(permission.length!=state.allowdPermission.length)
    {
        state.permission=false;
    }

}
else{
    localStorage.removeItem("token");
    localStorage.removeItem("permission");
    window.location.href="/login";
  }

        return state.permission? props.children:null

}
