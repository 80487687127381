import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch,Redirect } from 'react-router-dom';
import './App.scss';
import axios from "axios";
import Auth from './containers/Auth/Auth'
import AuthRouter from './containers/Auth/AuthRouter';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Tv=React.lazy(()=> import('./views/Tv/direct-tv'));

class App extends Component {

  render() {
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
             <Route exact path="/reports/tv-view" name="TV" render={props => <Tv {...props}/>}/> 
              <AuthRouter permission={[]} exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
          
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />


              {localStorage.getItem("token")!==null?(<Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />):(<Redirect from="/" to="/login" />) }

            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
