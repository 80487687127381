import React from 'react'
import { isArray } from 'util';
import { Route,Redirect } from 'react-router-dom';
export default function AuthRouter (props)
{
let state={}
        let userPermission=JSON.parse(localStorage.getItem("permission"));
if(userPermission && isArray(userPermission))
{
    state={
        userPermission:userPermission,
        allowdPermission:props.permission,
        permission:true
    }
    let permission=state.allowdPermission.filter(allow=>{
        let allowed=state.userPermission.find(user_per=>user_per.key===allow)
        return allowed?true:false;
    })
    if(permission.length!==state.allowdPermission.length)
    {
        state.permission=false;
    }
    
}else{
    localStorage.removeItem("token");
    localStorage.removeItem("permission");
    window.location.href="/login";
  }
       
    
    
        return <Route {...props} render={(renderprops) => (
            state.permission
              ? <props.render {...renderprops} />
              : <Redirect to='/404'/>
          )} />
    
}